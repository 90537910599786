import EventCard from "./components/EventCard";
import "./MenottiInfoApp.css";

async function getMenottiInfo() {
  const response = await fetch("data/menotti_info.json");
  return await response.json();
}

const menottiInfo = await getMenottiInfo();

function MenottiInfoApp() {
  return (
    <div className="m-4 flex flex-col grid justify-items-center">
      <div>
        {menottiInfo?.map((event) => (
          <EventCard key={event.title} event={event} />
        ))}
      </div>
    </div>
  );
}

export default MenottiInfoApp;
